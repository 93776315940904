/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../../common/standard-form-module';
import auth from '../../../components/auth/auth';
import {adnFormHelper} from "../../common/controller/form-helper";
import userEditRolesTemplate from "./user-edit-roles-template.html";
import userEditRolesViewOnlyTemplate from "./user-edit-roles-view-only-template.html";
import {LOCALES} from "../../common/constants/locales";
import {LocaleHelper} from "../../../components/util/locale-helper";
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = 'user-controller';

angular.module(MODULE_NAME, [standardFormModule, auth])

  .controller('UserCtrl', function($q, $window, $templateCache, TeamGroup, $stateParams, LoginStats, tmhDynamicLocale, Team, Role, Network, LocalNetworkProfile, localUserProfile, authenticatedUserResource, LocalUserPermissions, model, modelComms) {
    const ctrl = this,
      queries = [],
      networkId = LocalNetworkProfile.getNetworkId();

    ctrl.blockFields = model.username === 'adnuntius@adnuntius.com' && localUserProfile.get('username') !== 'adnuntius@adnuntius.com';

    $templateCache.put("userEditRolesTemplate.html", userEditRolesTemplate);
    $templateCache.put("userEditRolesViewOnlyTemplate.html", userEditRolesViewOnlyTemplate);

    LoginStats.getForUser(model.id).then(function(data) {
      ctrl.lastLogins = data;
    });

    ctrl.showSlim = LocalNetworkInfo.obtain().getInfo('teamsCount') > 10;
    ctrl.showSlimBasis = ctrl.showSlim;

    ctrl.locales = LOCALES;

    ctrl.currencies = LocalNetworkProfile.get('availableCurrencies');
    ctrl.defaultCurrency = LocalNetworkProfile.get("defaultCurrency");

    const applications = [];
    if (LocalNetworkProfile.isDataPlatform()) {
      applications.push('DATA');
    }
    if (LocalNetworkProfile.isAdvertisingPlatform()) {
      applications.push('ADVERTISING');
    }
    ctrl.applications = _.filter(LocalUserPermissions.getApps(), function(a) {
      return a !== 'SELF_SERVICE';
    });

    const currentApp = localUserProfile.getCurrentApp();
    ctrl.applications = _.orderBy(ctrl.applications, function(a) {
      return a === currentApp ? 0 : 1;
    });

    ctrl.networkGroup = {tagId: networkId};
    ctrl.isCurrentUser = model.id === localUserProfile.get('userId');

    ctrl.isAxPublisherAdmin = LocalUserPermissions.hasNetworkPermission('AX_PUBLISHER_ADMIN');
    ctrl.isAxAdvertiserAdmin = LocalUserPermissions.hasNetworkPermission('AX_ADVERTISER_ADMIN');
    ctrl.isSuiAdmin = LocalUserPermissions.hasNetworkPermission('SELF_SERVICE_USER_ADMIN');

    ctrl.isAxAdmin = ctrl.isAxPublisherAdmin || ctrl.isAxAdvertiserAdmin;

    ctrl.isNew = $stateParams.isNew;

    let queryType = {};
    if (ctrl.isAxPublisherAdmin) {
      queryType = { "where": 'type = AX_PUBLISHER'};
    } else if (ctrl.isAxAdvertiserAdmin) {
      queryType = { "where": 'type = AX_ADVERTISER'};
    } else if (ctrl.isSuiAdmin) {
      queryType = { "where": 'type in SUI'};
    }

    queries.push(Team.query(queryType).$promise.then(function(page) {
      ctrl.teams = page.results;

      _.forEach(ctrl.teams, function(team) {
        _.forEach(ctrl.applications, function(app) {
          let roleTeam = _.find(ctrl.rolesByGroup, function(rg) {
            return rg.team && rg.team.id === team.id && _.get(rg.role, 'application') === app;
          });
          if (roleTeam) {
            _.set(team, [app, 'role'], roleTeam.role);
          }
        });
      });
    }));
    if (ctrl.applications.indexOf("ADVERTISING") > -1) {
      queries.push(TeamGroup.query(queryType).$promise.then(function(page) {
        ctrl.teamGroups = page.results;

        _.forEach(ctrl.teamGroups, function(teamGroup) {
          _.forEach(ctrl.applications, function(app) {
            let roleTeam = _.find(ctrl.rolesByGroup, function(rg) {
              return rg.teamGroup && rg.teamGroup.id === teamGroup.id && _.get(rg.role, 'application') === app;
            });
            if (roleTeam) {
              _.set(teamGroup, [app, 'role'], roleTeam.role);
            }
          });
        });
      }));
    }
    queries.push(Network.get({id: networkId}).$promise.then(function(network) {
      ctrl.networkGroup.name = network.name;

      _.forEach(ctrl.applications, function(app) {
        let rg = _.find(ctrl.rolesByGroup, function(rg) {
          return !rg.team && _.get(rg.role, 'application') === app;
        });
        if (rg) {
          _.set(ctrl.networkGroup, [app, 'role'], rg.role);
        }
      });
    }));
    queries.push(Role.query().$promise.then(function(page) {
      let roles = page.results;
      _.forEach(ctrl.applications, function(app) {
        ctrl.networkRoles = ctrl.networkRoles || {};
        ctrl.networkRoles[app] = _.filter(roles, function(r) {
          if (!ctrl.isCurrentUser && ctrl.isAxAdmin) {
            return r.scope === "NETWORK" && r.application === app && r.permissions.length === 0;
          } else {
            return r.scope === "NETWORK" && r.application === app;
          }
        });

        ctrl.roles = ctrl.roles || {};
        if (app === 'ADVERTISING') {
          ctrl.roles[app] = _.filter(roles, function (r) {
            return r.scope === "TEAM" && r.application === app && r.permissions.indexOf('SELF_SERVICE_OWN_ADVERTISING') === -1 && r.permissions.indexOf('AX_ADVERTISER') === -1 && r.permissions.indexOf('AX_PUBLISHER') === -1 && r.permissions.indexOf('CREATIVE_AGENCY') === -1;
          });
          ctrl.roles[app]['AX_ADVERTISER'] = _.filter(roles, function (r) {
            return r.scope === "TEAM" && r.application === app && ((!ctrl.isAxAdvertiserAdmin && r.permissions.indexOf('SELF_SERVICE_OWN_ADVERTISING') === -1 && r.permissions.indexOf("CREATIVE_AGENCY") === -1) || r.permissions.indexOf('AX_ADVERTISER') >= 0 || r.permissions.indexOf('AX_PUBLISHER') >= 0);
          });
          ctrl.roles[app]['AX_PUBLISHER'] = _.filter(roles, function (r) {
            return r.scope === "TEAM" && r.application === app && ((!ctrl.isAxPublisherAdmin && r.permissions.indexOf('AX_ADVERTISER') === -1 && r.permissions.indexOf('SELF_SERVICE_OWN_ADVERTISING') === -1 && r.permissions.indexOf("CREATIVE_AGENCY") === -1) || r.permissions.indexOf('AX_PUBLISHER') >= 0);
          });
          ctrl.roles[app]['SUI'] = _.filter(roles, function (r) {
            return r.scope === "TEAM" && r.application === app && ((!ctrl.isSuiAdmin && r.permissions.indexOf('AX_ADVERTISER') === -1 && r.permissions.indexOf('AX_PUBLISHER') === -1) || r.permissions.indexOf('SELF_SERVICE_OWN_ADVERTISING') >= 0);
          });
          ctrl.roles[app]['CREATIVE_AGENCY'] = _.filter(roles, function (r) {
            return r.scope === "TEAM" && r.application === app && ((!ctrl.isAxAdvertiserAdmin && r.permissions.indexOf('AX_ADVERTISER') === -1 && r.permissions.indexOf('AX_PUBLISHER') === -1) || r.permissions.indexOf('CREATIVE_AGENCY') >= 0);
          });
        } else {
          ctrl.roles[app] = _.filter(roles, function (r) {
            return r.scope === "TEAM" && r.application === app;
          });
        }
      });
    }));

    let prevLocale = '';
    let afterInit = function() {
      if (ctrl.isNew) {
        ctrl.model.objectState = 'ACTIVE';
      }
      ctrl.updatePassword = false;
      ctrl.model.password = '';

      ctrl.rolesByGroup = {};
      if (ctrl.model.userRoles) {
        let rolesByNetwork = _.find(ctrl.model.userRoles.rolesByNetwork, ['network.tagId', networkId]);
        if (rolesByNetwork && rolesByNetwork.roles) {
          ctrl.rolesByGroup = angular.copy(rolesByNetwork.roles);
        }
      }

      prevLocale = _.clone(ctrl.model.locale);
      ctrl.model.locale = ctrl.model.locale || LocaleHelper.getNavigatorLanguage($window);
      if (LOCALES.indexOf(ctrl.model.locale) < 0) {
        let split = ctrl.model.locale.split("_");
        if (LOCALES.indexOf(split[0]) > -1) {
          ctrl.model.locale = split[0];
        }

        if (LOCALES.indexOf(ctrl.model.locale) < 0) {
          ctrl.model.locale = 'en_GB';
        }
      }

      $q.all(queries).then(function() {
        ctrl.model.networkGroup = ctrl.model.networkGroup || angular.copy(ctrl.networkGroup);
        // assign full role details to user
        _.forEach(ctrl.applications, function(app) {
          const currRole = _.get(ctrl.model, ['networkGroup', app, 'role']);
          if (currRole) {
            const foundRole = _.find(ctrl.networkRoles[app], function(role) {
              return role.id === currRole.id;
            });
            if (foundRole) {
              ctrl.model.networkGroup[app].role = foundRole;
            }
          }
        });

        _.forEach(ctrl.model.teams || ctrl.teams, function(sg) {
          if (sg.role) {
            sg.role = _.find(ctrl.roles, ['id', sg.role.id]);
          }
        });
        ctrl.model.teams = ctrl.model.teams || angular.copy(ctrl.teams);

        _.forEach(ctrl.model.teamGroups || ctrl.teamGroups, function(sg) {
          if (sg.role) {
            sg.role = _.find(ctrl.roles, ['id', sg.role.id]);
          }
        });
        ctrl.model.teamGroups = ctrl.model.teamGroups || angular.copy(ctrl.teamGroups);
      });

      ctrl.inNetwork = LocalUserPermissions.userPartOf(ctrl.model, networkId);
    };

    adnFormHelper.setUpForm(ctrl, model, {
      afterInit: afterInit,
      modelComms: modelComms,
      afterSave: function() {
        ctrl.updatePassword = false;
        ctrl.showPasswordChars = false;
        ctrl.model.password = '';

        if (ctrl.model.id === localUserProfile.get('userId')) {
          authenticatedUserResource.get().then(function(authUser) {
            LocalUserPermissions.create(authUser);
          });
        }

        if (localUserProfile.get('userId') === ctrl.model.id) {
          localUserProfile.set('locale', ctrl.model.locale);
          localUserProfile.set('displayName', ctrl.model.displayName);
          localUserProfile.set('name', ctrl.model.name);
          localUserProfile.set('twoFactorAuth', ctrl.model.twoFactorAuth);
          localUserProfile.set('canMasquerade', ctrl.model.canMasquerade);
          localUserProfile.set('preferredCurrency', ctrl.model.preferredCurrency);

          if (prevLocale !== ctrl.model.locale) {
            tmhDynamicLocale.set(LocaleHelper.toAngularFormat(ctrl.model.locale));
          }
        }
        prevLocale = ctrl.model.locale;

        ctrl.inNetwork = LocalUserPermissions.userPartOf(ctrl.model, networkId);
      }
    });

    ctrl.cancelPasswordUpdate = function() {
      ctrl.updatePassword = false;
      ctrl.model.password = '';
      ctrl.model.password2 = '';
    };

    ctrl.onBeforeSubmit = function() {
      let baseNetworkRoles = [];
      _.forEach(applications, function(app) {
        if (_.get(ctrl.model.networkGroup, [app, 'role'])) {
          baseNetworkRoles.push({network: {id: networkId}, role: {id: ctrl.model.networkGroup[app].role.id}});
        }
      });
      ctrl.model.userRoles = {rolesByNetwork: [{network: {id: networkId}, roles: baseNetworkRoles}]};

      let networkRoles = _.find(ctrl.model.userRoles.rolesByNetwork, ['network.id', networkId]);
      if (networkRoles && networkRoles.roles) {
        _.forEach(ctrl.model.teams, function(team) {
          _.forEach(ctrl.applications, function(app) {
            const appTeam = team[app];
            if (appTeam && appTeam.role && appTeam.role.id) {
              networkRoles.roles.push({team: {id: team.id}, role: (appTeam.role && appTeam.role.id) ? {id: appTeam.role.id} : {id: null}});
            }
          });
        });
        _.forEach(ctrl.model.teamGroups, function(team) {
          _.forEach(ctrl.applications, function(app) {
            const appTeam = team[app];
            const hasManageSystemPerm = _.get(ctrl.model, ['networkGroup', app, 'role', 'permissions'], []).indexOf("MANAGE_SYSTEM") > -1;
            if (appTeam && appTeam.role && appTeam.role.id) {
              networkRoles.roles.push({teamGroup: {id: team.id}, role: (appTeam.role && appTeam.role.id && hasManageSystemPerm) ? {id: appTeam.role.id} : {id: null}});
            }
          });
        });
      }
    };
  });

export default MODULE_NAME;