/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';

import _ from "lodash";
import {MEDIA_TYPE, MediaTypeHelper} from "../../components/util/media-type-helper";
import {simplifyFee} from "../line-item/line-item-controller";

const MODULE_NAME = 'creative-controller-helper';

export const DEFAULT_LAYOUT_TYPES = ['IMAGE', 'MIXED', 'TEXT', 'HTML', 'VIDEO', 'AUDIO', 'THIRD_PARTY', 'FLASH'];

angular.module(MODULE_NAME, [])

  .factory('CreativeHelper', function($q, Layout, $filter, $stateParams, LocalNetworkProfile) {

    return {
      isolateAssetComponents: function(layouts) {
        _.forEach(layouts, function(layout) {
          layout.assetComponents = _.filter(layout.layoutComponents, function(lc) {
            return lc.type === 'ASSET';
          });
        });
      },
      deriveFee: function(lineItemPromise, ctrl, fee) {
        return $q.when(lineItemPromise).then(function() {
          const grossCpm = _.cloneDeep(_.get(ctrl, ['lineItem', 'bidSpecification', 'cpm']));
          const grossCpc = _.cloneDeep(_.get(ctrl, ['lineItem', 'bidSpecification', 'cpc']));
          const referenceCurrency = _.get(grossCpm, ['currency']) || _.get(grossCpc, ['currency']) || 'NA';
          return simplifyFee(LocalNetworkProfile, referenceCurrency, fee);
        });
      },
      getLayouts: function(ctrl, defaultLayoutType, lineItemPromise) {
        const CreativeHook = this;
        return Layout.query({
          creativeId: _.get(ctrl, ['model', 'id']),
          lineItemId: _.get(ctrl, ['model', 'lineItem', 'id']),
          creativeSetId: _.get(ctrl, ['model', 'creativeSet', 'id']),
          creativeBundleId: _.get(ctrl, ['model', 'creativeBundle', 'id'])
        }).$promise.then(function(page) {
          let layouts = page.results;

          _.forEach(layouts, function(l) {
            let indexValue = DEFAULT_LAYOUT_TYPES.indexOf(l.detectedCategory || l.type);

            $q.when(CreativeHook.deriveFee(lineItemPromise, ctrl, _.get(l, ['advertiserFee', 'fee'])).then(function(fee) {
              if (fee) {
                l.name = l.name + ' - ' + $filter('isoCurrency')(fee.monetary.amount, fee.monetary.currency) + ' fee';
              }
            }));
            l.ogLayoutType = l.layoutType;
            if (l.layoutCategory) {
              l.layoutType = l.layoutCategory;
              l.layoutTypeOrderBy = 0;
            } else if (indexValue > -1) {
              l.layoutType = l.type;
              l.layoutTypeOrderBy = indexValue + 1;
            } else {
              l.layoutType = defaultLayoutType;
              l.layoutTypeOrderBy = DEFAULT_LAYOUT_TYPES.length + 1;
            }
          });

          ctrl.layouts = _.orderBy(layouts, ['layoutTypeOrderBy', 'asc'], ['name', 'asc']);
          CreativeHook.isolateAssetComponents(ctrl.layouts);
        });
      },
      checkHtmlUrls: function(ctrl) {
        if (ctrl.primaryHtmlAsset) {
          ctrl.primaryHtmlAsset.hasUrls = !_.isEmpty(ctrl.primaryHtmlAsset.htmlUrls);
        }
      },
      doLayoutParams: function(ctrl, withModel) {
        const theModel = withModel || ctrl.model;

        let allLayoutParameters = _.assign({}, theModel.cToText, theModel.cToUrls, theModel.cToChoices);
        _.forEach(theModel.cToAssets, function(cToA, key) {
          if (!cToA || !cToA.id) {
            return;
          }
          allLayoutParameters[key] = {
            id: cToA.id
          };
        });
        theModel.layoutParameters = angular.copy(allLayoutParameters);
        _.forEach(allLayoutParameters, function(lpValue, lpKey) {
          if (!_.find(ctrl.allComponents, ['tag', lpKey])) {
            delete theModel.layoutParameters[lpKey];
          }
        });
        delete theModel.constraintsToAssets;
        delete theModel.constraintsToText;
        delete theModel.constraintsToUrls;
        delete theModel.constraintsToChoices;
      },
      updateDefaultName: function(ctrl, justGoDefaultName) {
        if (!ctrl.isNew) {
          return;
        }
        const networkDefaults = LocalNetworkProfile.getDefaults();
        const defCreativeName = networkDefaults.defaultCreativeName;
        const noFileNameReplace = defCreativeName.indexOf("{fileName}") < 0;
        if (noFileNameReplace && !justGoDefaultName) {
          return;
        }
        const asset = _.find(ctrl.model.cToAssets, function(a) {
          return a;
        });
        if (!asset || !asset.fileName) {
          return;
        }
        ctrl.model.name = justGoDefaultName && noFileNameReplace ? asset.fileName || 'Creative' : defCreativeName.replace("{fileName}", asset.fileName).replace("{lineItemName}", $stateParams.lineItemName);
      },
      updateCreativeDims: function(ctrl, prevAssetAssignments, forceGetFromAsset) {
        const noText = _.filter(ctrl.allComponents, function(c) {
          return c.type === "TEXT" || c.type === "CHOICE";
        }).length === 0;
        const images = _.filter(ctrl.allComponents, function(c) {
          return c.type === "ASSET" && !MediaTypeHelper.isMediaType(c.mimeTypes, MEDIA_TYPE.html);
        });
        const htmls = _.filter(ctrl.allComponents, function(c) {
          return c.type === "ASSET" && MediaTypeHelper.isMediaType(c.mimeTypes, MEDIA_TYPE.html);
        });
        if (forceGetFromAsset || noText) {
          if ((images.length === 1 && htmls.length === 0) || (htmls.length === 1 && images.length === 0)) {
            const currentTag = images.length === 1 ? images[0].tag : htmls[0].tag;
            const selAsset = ctrl.model.cToAssets[currentTag];
            if (selAsset && prevAssetAssignments[currentTag]) {
              if (!_.isFinite(ctrl.model.width) || !_.isFinite(ctrl.model.height) || (prevAssetAssignments[currentTag].width === ctrl.model.width && prevAssetAssignments[currentTag].height === ctrl.model.height)) {
                ctrl.model.width = selAsset.width;
                ctrl.model.height = selAsset.height;
              }
            }
            prevAssetAssignments[currentTag] = selAsset;
          }
        }
      },
      matchConstraintsAndAssets: function(ctrl, mInitPromises, mLayoutAssetMatches, prevAssetAssignments, delayedPreview, newAssetUpload) {
        const CreativeHook = this;
        const loadConstraintsAndAssignDefaultAssets = function() {
          const layout = ctrl.model.layout;
          ctrl.isFullPage = ctrl.model.layout.fullPage;
          if (!layout || !layout.id) {
            // in this case, the layout has become inactive or deleted
            ctrl.allResolved = true;
            return;
          }

          ctrl.allComponents = layout.layoutComponents;

          const acsForLayout = _.filter(ctrl.allComponents, function(c) {
            return c.type === 'ASSET';
          });
          if (!mLayoutAssetMatches[layout.id]) {
            mLayoutAssetMatches[layout.id] = acsForLayout;
          }

          ctrl.model.cToAssets = ctrl.model.cToAssets || {};
          ctrl.model.cToText = ctrl.model.cToText || {};
          ctrl.model.cToUrls = ctrl.model.cToUrls || {};
          ctrl.model.cToChoices = ctrl.model.cToChoices || {};
          _.forEach(ctrl.model.layoutParameters, function(lpValue, lpKey) {
            let component = _.find(ctrl.allComponents, ['tag', lpKey]) || {};
            if (component.type === 'ASSET') {
              ctrl.model.cToAssets[lpKey] = ctrl.model.cToAssets[lpKey] || lpValue;
            } else if (component.type === 'URL') {
              ctrl.model.cToUrls[lpKey] = ctrl.model.cToUrls[lpKey] || lpValue;
            } else if (component.type === 'TEXT') {
              ctrl.model.cToText[lpKey] = ctrl.model.cToText[lpKey] || lpValue;
            } else if (component.type === 'CHOICE') {
              ctrl.model.cToChoices[lpKey] = ctrl.model.cToChoices[lpKey] || lpValue;
            }
          });

          _.forEach(ctrl.allComponents, function(component) {
            if (component.type === 'TEXT') {
              component.pattern = "";
              component.patternData = "";
              if (component.minLength >= 0 && component.maxLength >= 0) {
                component.pattern = "(.|\n|\t){" + component.minLength + "," + component.maxLength + "}";
                component.patternData = {minLength: component.minLength, maxLength: component.maxLength};
              }

              if (!Object.hasOwn(ctrl.model.cToText, component.tag) && component.defaultValue) {
                ctrl.model.cToText[component.tag] = component.defaultValue;
              }
            }
            if (component.type === 'CHOICE') {
              if (!ctrl.model.cToChoices[component.tag] && component.defaultValue && component.options.indexOf(component.defaultValue) > -1) {
                ctrl.model.cToChoices[component.tag] = component.defaultValue;
              }
            }
          });

          _.forEach(ctrl.model.cToAssets, function(asset) {
            const availableAsset = _.find(ctrl.model.assets, function(a) {
              return a && asset && a.id === asset.id;
            });
            const availableLibraryAsset = _.find(_.get(ctrl.model, "libraryCreativeAssets", []), function(a) {
              return a && asset && a.id === asset.id;
            });
            if (_.isString(asset) || _.isFinite(asset)) {
              // this can happen on layout transition
              asset = "";
            } else if (!availableLibraryAsset && asset && (!availableAsset || availableAsset.objectState === 'HIDDEN')) {
              asset.deleted = true;
            } else if (availableLibraryAsset) {
              asset.fromLibrary = true;
            }
          });

          ctrl.acsWithAssetMatches = mLayoutAssetMatches[layout.id];
          if (!ctrl.acsWithAssetMatches) {
            return;
          }

          ctrl.acsWithAssetMatches = _.keyBy(ctrl.acsWithAssetMatches, 'tag');
          // automatically assigns assets to layout's asset constraints.
          const usedAssetIds = {};
          let doPreview = false;
          _.forEach(ctrl.acsWithAssetMatches, function(acWithAssetMatches) {
            if (acWithAssetMatches.required !== 'OPTIONAL' && acWithAssetMatches.tag && (!ctrl.model.cToAssets[acWithAssetMatches.tag] || _.get(ctrl.model.cToAssets[acWithAssetMatches.tag], "deleted") === true) && acWithAssetMatches.matchingAssets && acWithAssetMatches.matchingAssets.length > 0) {
              let asset = _.find(acWithAssetMatches.matchingAssets, function(matchingAsset) {
                return !usedAssetIds[matchingAsset.id];
              });
              asset = asset || acWithAssetMatches.matchingAssets[0];
              ctrl.model.cToAssets[acWithAssetMatches.tag] = asset;
              usedAssetIds[asset.id] = asset.id;
              doPreview = true;
            }
          });

          // do the html selection, which assumes one html mime type asset constraint available
          _.forEach(ctrl.acsWithAssetMatches, function(acWithAssetMatches) {
            if (MediaTypeHelper.isMediaType(acWithAssetMatches.mimeTypes, MEDIA_TYPE.html)) {
              // get the full asset information
              let htmlAsset = ctrl.model.cToAssets[acWithAssetMatches.tag];
              if (htmlAsset && htmlAsset.id && htmlAsset.id !== _.get(ctrl.primaryHtmlAsset, 'id')) {
                ctrl.primaryHtmlAsset = _.find(acWithAssetMatches.matchingAssets, function(a) {
                  return a.id === htmlAsset.id;
                });
                CreativeHook.checkHtmlUrls(ctrl);
              }
            }
          });

          _.forEach(ctrl.model.cToAssets, function(val, tag) {
            prevAssetAssignments[tag] = val;
            if (!prevAssetAssignments[tag] || !prevAssetAssignments[tag].width) {
              prevAssetAssignments[tag] = _.find(ctrl.model.assets, function(asset) {
                return asset && val && asset.id && val.id && asset.id === val.id;
              }) || val;
            }
          });

          if (newAssetUpload) {
            CreativeHook.updateDefaultName(ctrl);
            if (_.isFunction(ctrl.eventHook.onAssetUpdate)) {
              ctrl.eventHook.onAssetUpdate();
            }
          }

          if (doPreview) {
            if (newAssetUpload) {
              CreativeHook.updateCreativeDims(ctrl, prevAssetAssignments);
            }
            delayedPreview();
          }
          ctrl.allResolved = true;
        };

        return $q.all(mInitPromises).then(function() {
          let layout = ctrl.model.layout;
          let creative = ctrl.model;
          if (!layout) {
            ctrl.allResolved = true;
            return;
          }
          if (!creative) {
            return loadConstraintsAndAssignDefaultAssets();
          }
          let acsWithAssets = [];
          let layoutAcs = layout.assetComponents;
          ctrl.primaryHtmlAsset = undefined;
          _.forEach(layoutAcs, function(ac) {
            // can only be one html asset constraint per layout
            ac.matchingAssets = _.filter(_.get(creative, ['assets'], []).concat(_.get(ctrl, ['model', 'libraryCreativeAssets'], [])), function(asset) {
              let assetMediaType = MediaTypeHelper.isMediaType(ac.mimeTypes, MEDIA_TYPE.html);
              let fileCheck = asset.objectState !== 'HIDDEN' && _.includes(ac.mimeTypes, asset.mimeType) &&
                (ac.maxFileSizeBytes < 1 || asset.fileSizeBytes <= ac.maxFileSizeBytes) &&
                (!asset.htmlCreativeType || assetMediaType) &&
                (!assetMediaType || asset.primaryHtmlAsset);
              if (asset.primaryHtmlAsset && assetMediaType) {
                ctrl.primaryHtmlAsset = asset;
              }
              return fileCheck;
            });
            acsWithAssets.push(ac);
          });
          mLayoutAssetMatches[layout.id] = acsWithAssets;
          CreativeHook.checkHtmlUrls(ctrl);

          _.forEach(creative.assets, function(asset) {
            asset.matchingAcs = asset.matchingAcs || {};
            asset.matchingAcs[layout.id] = _.map(_.filter(acsWithAssets, function(ac) {
              return _.find(ac.matchingAssets, ['id', asset.id]);
            }), 'tag');
          });
          loadConstraintsAndAssignDefaultAssets();
          ctrl.allowPreviews = true;
        });
      }
    };
  });

export default MODULE_NAME;
