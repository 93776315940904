/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardFormModule from '../common/standard-form-module';
import {Uuid} from "../../components/util/uuid";
import {LocalBulkCreativeInfo} from "../../components/session/local-bulk-creative-info";
import {creativeAttach, creativeBulkAction, creativeCopyInBulk} from "./creative-commons";
import {MediaTypeHelper} from "../../components/util/media-type-helper";
import {LocalNetworkInfo} from "../../components/session/local-network-info";

const MODULE_NAME = 'bulk-creative-controller';

angular.module(MODULE_NAME, [
  standardFormModule
])

  .controller('BulkCreativeCtrl', function($timeout, $uibModal, $state, $q, creativeType, Asset, Layout, Team, LocalNetworkProfile, LibraryCreative, Creative, adnListHelper, LocalUserPermissions) {
    const ctrl = this;
    ctrl.model = {};
    ctrl.bulk = {};

    ctrl.isPureMarketplace = LocalNetworkProfile.isPureMarketplacePlatform();
    ctrl.bulkCreatives = creativeType === 'CREATIVE' ? LocalBulkCreativeInfo.obtain().getCreatives() : LocalBulkCreativeInfo.obtain().getLibraryCreatives();
    ctrl.view = ctrl.bulkCreatives.length > 0 ? 'LIST' : 'UPLOAD';
    ctrl.perms = LocalUserPermissions.getAllNetworkPermissions();
    ctrl.type = 'BULK_IMAGE';
    ctrl.creativeType = creativeType;
    ctrl.creativeFields = ['name', {param: 'Layout', label: 'layout'}, {param: 'LineItem', label: 'lineItem'}, {param: '', label: 'URLs'}, {label: 'dimensions', param: 'width'}, {param: '', label: 'info'}, 'updateTime', {param: '', label: 'targeting'}];
    ctrl.hasCreativeSet = LocalNetworkInfo.obtain().getInfo('creativeSetCount') > 0;
    ctrl.attachSuffix = "Line Item" + (ctrl.hasCreativeSet ? " or Creative Set" : "");


    ctrl.lineItemOption = LocalNetworkInfo.obtain().getInfo("lineItemsCount") > 0;
    ctrl.creativeSetOption = LocalNetworkInfo.obtain().getInfo("creativeSetCount") > 0;

    if (ctrl.creativeType === 'LIBRARY_CREATIVE') {
      Team.query({minimal: true}).$promise.then(function(page) {
        ctrl.allTeams = page.results;
      });
      ctrl.creativeFields = ['name', {param: 'Layout', label: 'layout'}, {param: '', label: 'URLs'}, {label: 'dimensions', param: 'width'}, 'updateTime', {param: '', label: 'targeting'}];
    }

    ctrl.bulkAction = creativeBulkAction(ctrl, {$uibModal: $uibModal});
    const CreativeResource = creativeType === 'CREATIVE' ? Creative : LibraryCreative;

    ctrl.clearPreviousUploads = function() {
      ctrl.bulkSelection = {};
      LocalBulkCreativeInfo.obtain().setCreatives([]);
      ctrl.bulkCreatives = LocalBulkCreativeInfo.obtain().getCreatives();
      adnListHelper.setUpList(ctrl, CreativeResource, false, null, {id: _.map(ctrl.bulkCreatives, 'id'), pageSize: 1000});
    };

    ctrl.attach = function() {
      creativeAttach(ctrl, {
        $uibModal: $uibModal,
        Creative: CreativeResource,
        $q: $q
      }, function(updatedCreatives, bulkSelection) {
        _.forEach(updatedCreatives, function(uc) {
          ctrl.bulkCreatives.push(uc);
        });
        adnListHelper.setUpList(ctrl, CreativeResource, false, null, {id: _.map(ctrl.bulkCreatives, 'id'), pageSize: 1000});
        LocalBulkCreativeInfo.obtain().setCreatives(ctrl.bulkCreatives);
        ctrl.bulkCreatives = LocalBulkCreativeInfo.obtain().getCreatives();
        ctrl.bulkSelection = bulkSelection;
      });
    };

    ctrl.copyInBulk = function() {
      creativeCopyInBulk(ctrl, {
        $uibModal: $uibModal,
        Creative: CreativeResource,
        $q: $q
      }, function(updatedCreatives, bulkSelection) {
        _.forEach(updatedCreatives, function(uc) {
          ctrl.bulkCreatives.push(uc);
        });
        adnListHelper.setUpList(ctrl, CreativeResource, false, null, {id: _.map(ctrl.bulkCreatives, 'id'), pageSize: 1000});
        LocalBulkCreativeInfo.obtain().setCreatives(ctrl.bulkCreatives);
        ctrl.bulkCreatives = LocalBulkCreativeInfo.obtain().getCreatives();
        ctrl.bulkSelection = bulkSelection;
      });
    };

    if (ctrl.view === 'LIST') {
      adnListHelper.setUpList(ctrl, CreativeResource, false, null, {id: _.map(ctrl.bulkCreatives, 'id'), pageSize: 1000});
    }

    if ($state.previousParams.advertiser) {
      ctrl.advertiserSpecified = !!$state.previousParams.advertiser;
      ctrl.advertiser = $state.previousParams.advertiser;
    }

    let firstThirdPartyLayout,
      firstImageLayout,
      firstHtmlLayout,
      firstVideoLayout;
    Layout.query({excludeInvalid: true}).$promise.then(function(data) {
      ctrl.thirdPartyLayouts = _.orderBy(_.filter(data.results, function(l) {
        return l.layoutType === 'THIRD_PARTY';
      }), function(l) {
        return _.get(l, ['externalReference'], '').toLowerCase().indexOf('priority') > -1 ? 0 : 1;
      });
      ctrl.imageLayouts = _.orderBy(_.filter(data.results, function(l) {
        return _.find(l.layoutComponents, function(lc) {
          return lc.type === 'ASSET' && _.get(MediaTypeHelper.getMediaType(lc.mimeTypes), ['image']);
        });
      }), function(l) {
        return _.get(l, ['externalReference'], '').toLowerCase().indexOf('priority') > -1 ? 0 : 1;
      });
      ctrl.videoLayouts = _.orderBy(_.filter(data.results, function(l) {
        return _.find(l.layoutComponents, function(lc) {
          return lc.type === 'ASSET' && _.get(MediaTypeHelper.getMediaType(lc.mimeTypes), ['video']);
        });
      }), function(l) {
        return _.get(l, ['externalReference'], '').toLowerCase().indexOf('priority') > -1 ? 0 : 1;
      });
      ctrl.htmlLayouts = _.orderBy(_.filter(data.results, function(l) {
        return l.layoutType === 'HTML';
      }), function(l) {
        return _.get(l, ['externalReference'], '').toLowerCase().indexOf('priority') > -1 ? 0 : 1;
      });
      firstHtmlLayout = _.get(ctrl.htmlLayouts, [0]);
      firstThirdPartyLayout = _.get(ctrl.thirdPartyLayouts, [0]);
      firstImageLayout = _.get(ctrl.imageLayouts, [0]);
      firstVideoLayout = _.get(ctrl.videoLayouts, [0]);
    }).finally(function() {
      ctrl.layoutQueryDone = true;
    });

    let bulkForm;
    ctrl.setForm = function(form) {
      bulkForm = form;
    };

    ctrl.copyInLayoutBulk = function(creative) {
      const currentLayout = _.cloneDeep(creative.layout);
      _.forEach(ctrl.model.creatives, function(c) {
        c.layout = currentLayout || null;
        ctrl.layoutChange(c);
      });
    };
    ctrl.copyInLineItemBulk = function(creative) {
      const currentLineItems = _.cloneDeep(creative.lineItems);
      _.forEach(ctrl.model.creatives, function(c) {
        c.lineItems = _.cloneDeep(currentLineItems || null);
      });
    };
    ctrl.copyInTeamsBulk = function(creative) {
      const currentTeams = _.cloneDeep(creative.teams);
      _.forEach(ctrl.model.creatives, function(c) {
        c.teams = currentTeams || null;
      });
    };

    function updateLayout(creative, layout) {
      const prevComps = _.cloneDeep(creative.bulkLayoutComponents);
      creative.bulkLayoutComponents = [];
      _.forEach(layout.layoutComponents, function(lc) {
        const matchingTag = _.find(prevComps, function(comp) {
          return comp.tag === lc.tag;
        });
        creative.bulkLayoutComponents.push({
          tag: lc.tag,
          type: lc.type,
          textType: lc.textType,
          name: lc.name || lc.tag,
          required: lc.required,
          mimeTypes: lc.mimeTypes,
          value: _.get(matchingTag, ['value'], '')
        });
      });
    }

    function addNewCreativeSet(creative) {
      const newId = Uuid.generate();
      creative.creativeSets[newId] = {uiId: newId};
    }

    ctrl.removeCreativeSet = function(creative, index) {
      delete creative.creativeSets[index];
      if (_.isEmpty(creative.creativeSets)) {
        addNewCreativeSet(creative);
      }
    };

    ctrl.addCreativeSet = function(index) {
      addNewCreativeSet(ctrl.model.creatives[index]);
    };

    function addNewLineItem(creative) {
      const newId = Uuid.generate();
      creative.lineItems[newId] = {uiId: newId};
    }

    ctrl.removeLineItem = function(creative, index) {
      delete creative.lineItems[index];
      if (_.isEmpty(creative.lineItems)) {
        addNewLineItem(creative);
      }
    };

    ctrl.addLineItem = function(index) {
      addNewLineItem(ctrl.model.creatives[index]);
    };

    ctrl.layoutChange = function(creative) {
      const compWithAsset = _.find(creative.bulkLayoutComponents, function(blc) {
        return blc.type === 'ASSET' && blc.mimeTypes && _.get(MediaTypeHelper.getMediaType(blc.mimeTypes), ['image']) && blc.value;
      });
      updateLayout(creative, creative.layout);
      const newComp = _.find(creative.bulkLayoutComponents, function(blc) {
        return blc.type === 'ASSET' && blc.mimeTypes && _.get(MediaTypeHelper.getMediaType(blc.mimeTypes), ['image']);
      });
      if (newComp && compWithAsset) {
        newComp.value = compWithAsset.value;
      }
    };

    ctrl.selectTeams = function(creative) {
      creative.teams = _.map(ctrl.allTeams, 'id');
    };

    ctrl.deselectTeams = function(creative) {
      creative.teams = [];
    };

    ctrl.bulkSubmit = function() {
      const bulkHtmlAssets = [];
      const uiCreatives = _.map(_.filter(ctrl.model.creatives, function(c) {
        return !c.skip;
      }), function(c) {
        let creative = {
          id: c.id || Uuid.generate(),
          name: c.name || 'Bulk Creative',
          lineItems: c.lineItems,
          creativeSets: c.creativeSets,
          width: c.width,
          height: c.height,
          layout: c.layout,
          layoutParameters: {},
          teams: c.teams
        };
        if (c.impTrackingUrl && c.impTrackingUrl.length > 1) {
          creative.impressionTrackingUrls = [c.impTrackingUrl];
        }
        _.forEach(c.bulkLayoutComponents, function(blc) {
          creative.layoutParameters[blc.tag] = blc.value;

          if (_.get(blc, ['value', 'primaryHtmlAsset']) && !_.isEmpty(_.get(blc, ['value', 'htmlUrls']))) {
            bulkHtmlAssets.push({id: blc.value.id, htmlUrls: blc.value.htmlUrls});
          }
        });
        return creative;
      });

      ctrl.hasError = false;
      bulkForm.$setValidity('validation');

      if (bulkHtmlAssets.length > 0) {
        Asset.bulkSave(null, bulkHtmlAssets);
      }


      const bulkSaveCreatives = [],
        copyCreatives = [],
        allCreatives = [];
      _.forEach(uiCreatives, function(c) {
        const goodLineItems = _.uniqBy(_.filter(c.lineItems, function(li) {
          return li.id;
        }), 'id');

        const goodCreativeSets = _.uniqBy(_.filter(c.creativeSets, function(cs) {
          return cs.id;
        }), 'id');

        const basicCreative = _.cloneDeep(c);
        basicCreative.id = Uuid.generate();
        delete basicCreative.lineItems;
        delete basicCreative.creativeSets;

        if (goodLineItems.length === 0 && goodCreativeSets.length === 0) {
          let theC = _.cloneDeep(basicCreative);
          if (_.get(ctrl, ['advertiser', 'id'])) {
            theC.advertiser = ctrl.advertiser;
          }
          bulkSaveCreatives.push(theC);
          allCreatives.push(theC);
        }

        _.forEach(goodLineItems, function(li, index) {
          const creative = _.cloneDeep(basicCreative);
          creative.lineItem = li;
          if (index === 0) {
            bulkSaveCreatives.push(creative);
          } else {
            creative.id = Uuid.generate();
            copyCreatives.push({
              copyCreativeId: basicCreative.id,
              liId: li.id,
              id: creative.id
            });
          }
          allCreatives.push(creative);
        });

        _.forEach(goodCreativeSets, function(cs, index) {
          const creative = _.cloneDeep(basicCreative);
          creative.creativeSet = cs;
          creative.id = Uuid.generate();
          if (index === 0) {
            bulkSaveCreatives.push(creative);
          } else {
            copyCreatives.push({
              copyCreativeId: basicCreative.id,
              csId: cs.id,
              id: creative.id
            });
          }
          allCreatives.push(creative);
        });
      });
      CreativeResource.bulkSave(bulkSaveCreatives).$promise.then(function() {
        const promises = [];
        _.forEach(copyCreatives, function(cc) {
          promises.push(CreativeResource.copyCreative(cc.copyCreativeId, cc.liId, cc.id, null, cc.csId));
        });
        $q.all(promises).then(function() {
          if (creativeType === 'CREATIVE') {
            LocalBulkCreativeInfo.obtain().setCreatives(allCreatives);
            ctrl.bulkCreatives = LocalBulkCreativeInfo.obtain().getCreatives();
          } else {
            LocalBulkCreativeInfo.obtain().setLibraryCreatives(allCreatives);
            ctrl.bulkCreatives = LocalBulkCreativeInfo.obtain().getLibraryCreatives();
          }
          adnListHelper.setUpList(ctrl, CreativeResource, false, null, {id: _.map(ctrl.bulkCreatives, 'id'), pageSize: 1000});
          ctrl.hasUpload = false;
          bulkForm.validSubmission = true;
          ctrl.model.creatives = [];
        });
      }).catch(function() {
        ctrl.hasError = true;
      }).finally(function() {
        bulkForm.$setValidity('validation', true);
        bulkForm.$setPristine();
      });
    };

    ctrl.refreshCodeMirror = function() {
      ctrl.refreshCodeMirrorVar = false;
      $timeout(function() {
        ctrl.refreshCodeMirrorVar = true;
      }, 200);
    };
    ctrl.refreshCodeMirror();

    ctrl.incorrectMimeTypeFiles;
    ctrl.filesFailedUpload;
    ctrl.cannotProcess = [];
    ctrl.eventHook = {
      onUpdate: function(asset, allFilesProcessed, uploadStats) {
        ctrl.isNew = false;

        if (!ctrl.incorrectMimeTypeFiles) {
          ctrl.incorrectMimeTypeFiles = _.cloneDeep(uploadStats.incorrectMimeType);
        }
        if (!ctrl.filesFailedUpload) {
          ctrl.filesFailedUpload = _.cloneDeep(uploadStats.filesFailedUpload);
        }

        const totalFilesCount = uploadStats.apiResponseCount + uploadStats.incorrectMimeType.length + uploadStats.filesFailedUpload.length;
        ctrl.loadingCreatives = totalFilesCount !== uploadStats.allFilesCount && totalFilesCount !== uploadStats.fileUploadLimit;

        if (ctrl.type === 'BULK_THIRD_PARTY') {
          ctrl.bulk.layout = firstThirdPartyLayout;
          ctrl.selectableLayouts = ctrl.thirdPartyLayouts;
        } else if (ctrl.type === 'BULK_HTML') {
          ctrl.bulk.layout = firstHtmlLayout;
          ctrl.selectableLayouts = ctrl.htmlLayouts;
        } else if (ctrl.type === 'BULK_IMAGE') {
          ctrl.bulk.layout = firstImageLayout;
          ctrl.selectableLayouts = ctrl.imageLayouts;
        } else if (ctrl.type === 'BULK_VIDEO') {
          ctrl.bulk.layout = firstVideoLayout;
          ctrl.selectableLayouts = ctrl.videoLayouts;
        }

        const unprocessedAssets = _.flatten(_.map(asset, function(a) {
          return a.results || a;
        }));
        const allAssets = _.filter(unprocessedAssets, function(a) {
          return !a.zipCdnId || a.primaryHtmlAsset;
        });
        const uiId = Uuid.generate();
        const csUiId = Uuid.generate();
        const currentCreatives = _.flatten(_.map(allAssets, function(a) {
          const creative = {
            name: a.fileName || 'Creative Name',
            width: a.width,
            height: a.height,
            lineItems: {},
            creativeSets: {},
            skip: false,
            layout: ctrl.bulk.layout
          };
          creative.lineItems[uiId] = {uiId: uiId};
          creative.creativeSets[csUiId] = {csUiId: csUiId};
          if (ctrl.type === 'BULK_IMAGE') {
            updateLayout(creative, ctrl.bulk.layout);
            const comp = _.find(creative.bulkLayoutComponents, function(blc) {
              return blc.type === 'ASSET' && blc.mimeTypes && _.get(MediaTypeHelper.getMediaType(blc.mimeTypes), ['image']);
            });
            comp.value = a;
            return creative;
          }
          if (ctrl.type === 'BULK_VIDEO') {
            updateLayout(creative, ctrl.bulk.layout);
            const comp = _.find(creative.bulkLayoutComponents, function(blc) {
              return blc.type === 'ASSET' && blc.mimeTypes && _.get(MediaTypeHelper.getMediaType(blc.mimeTypes), ['video']);
            });
            comp.value = a;
            return creative;
          }
          if (ctrl.type === 'BULK_HTML') {
            updateLayout(creative, ctrl.bulk.layout);
            const comp = _.find(creative.bulkLayoutComponents, function(blc) {
              return blc.type === 'ASSET' && blc.mimeTypes && _.get(MediaTypeHelper.getMediaType(blc.mimeTypes), ['html']);
            });
            comp.value = a;
            return creative;
          }
          return _.map(a.creatives, function(c) {
            c.name = c.creativeName || 'Creative Name';
            c.layout = ctrl.bulk.layout;
            c.lineItems = {};
            c.lineItems[uiId] = {uiId: uiId};
            c.creativeSets = {};
            c.creativeSets[csUiId] = {csUiId: csUiId};
            c.skip = false;
            updateLayout(c, ctrl.bulk.layout);
            const comp = _.find(c.bulkLayoutComponents, function(blc) {
              return blc.tag === 'thirdParty';
            });
            comp.value = c.tag;
            return c;
          });
        }));
        ctrl.model.creatives = ctrl.model.creatives || [];
        ctrl.model.creatives = ctrl.model.creatives.concat(currentCreatives);
        ctrl.hasUpload = ctrl.model.creatives.length > 0;
        ctrl.noValidAssets = !ctrl.loadingCreatives && !ctrl.hasUpload;
        if ((!currentCreatives || !currentCreatives.length) && unprocessedAssets.length > 0 && unprocessedAssets[0].zipCdnId) {
          ctrl.cannotProcess.push(unprocessedAssets[0]);
        }
        ctrl.refreshCodeMirror();
      }
    };
  });

export default MODULE_NAME;
