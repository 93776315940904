/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import {AUTH_EVENT} from "../auth/auth-constants";

import auth from '../auth/auth';
import localNetworkProfile from '../session/local-network-profile';
import workspaceRegistry from '../workspace-registry';
import {LocalNetworkInfo} from "../session/local-network-info";
import _ from "lodash";
import {LocalPausing} from "../session/local-pausing";
import {LocalDebugInfo} from "../session/local-debug-info";
import {LocaleHelper} from "./locale-helper";
import {zendeskPrefill} from "../../app/sign-in/zendesk-prefill";

const MODULE_NAME = "auth-helper";

angular.module(MODULE_NAME, [uiRouter, auth, localNetworkProfile, workspaceRegistry])

  .factory('adnAuthHelper', function(authenticatedUserResource, $window, $interval, $q, $log, tmhDynamicLocale, LocalUserPermissions, $rootScope, WorkspaceRegistry, LocalNetworkProfile, NetworkInfo, localUserProfile) {

    return {
      doAuth: function(ctrlProfile, credentials, masqueradeAttempt, networkTag) {
        return authenticatedUserResource.get().then(function(authUser) {
          let user = authUser.user,
            prevDefaultNetworkTagId = networkTag || localUserProfile.get('defaultNetworkTagId'),
            profile = localUserProfile.create(user, _.keys(authUser.appsByNetwork), authUser.profile, credentials.masqUsername && masqueradeAttempt ? credentials.username : null),
            isPrevUser = localUserProfile.isSameUser(ctrlProfile);

          // if it's the same user and the current network ID exists, restore their network preference
          if (isPrevUser && localUserProfile.hasNetwork(prevDefaultNetworkTagId)) {
            profile.defaultNetworkTagId = prevDefaultNetworkTagId;
            localUserProfile.save();
          }

          const showSupportWidget = _.get(profile, 'adnPrefs.showSupportWidget');
          let widgetTimer;
          const showWebWidget = function() {
            if (!$window.zE) {
              return;
            }
            $window.zE('webWidget', showSupportWidget !== false ? 'show' : 'hide');
            $interval.cancel(widgetTimer);
          };
          widgetTimer = $interval(showWebWidget, 500);

          localUserProfile.updateDefaultNetworkId(LocalNetworkProfile.get("networkTagId"));

          const allPromises = [LocalNetworkProfile.create(profile)];

          allPromises.push(NetworkInfo.get(function(data) {
            LocalNetworkInfo.obtain().setInfo(data);
          }).$promise);

          LocalUserPermissions.create(authUser);
          LocalPausing.obtain().destroy();
          const prevApp = localUserProfile.get('app');
          if (isPrevUser && _.includes(LocalUserPermissions.getApps(), prevApp)) {
            profile.app = prevApp;
            localUserProfile.save();
          }

          WorkspaceRegistry.sync();

          LocalDebugInfo.obtain().setSignIn(user.id, user.username, credentials.username, masqueradeAttempt ? credentials.masqUsername : null);

          const locale = user.locale || LocaleHelper.getNavigatorLanguage($window);
          const localePromise = !!locale && tmhDynamicLocale.set(LocaleHelper.toAngularFormat(locale)).catch(function(localeId) {
            $log.warn('Error loading locale', localeId);
          });
          allPromises.push(localePromise);

          return $q.all(allPromises).then(function() {
            $rootScope.$broadcast(AUTH_EVENT.loginSuccess, profile);
            zendeskPrefill($window, $interval, localUserProfile, LocalNetworkProfile);
          });
        });
      }
    };
  });

export default MODULE_NAME;