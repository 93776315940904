/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import _ from 'lodash';

import standardAnalysisModule from '../../common/standard-analysis-module';
import {LocalNetworkInfo} from "../../../components/session/local-network-info";

const MODULE_NAME = "team-group-analysis";

angular.module(MODULE_NAME, [standardAnalysisModule])

  .controller('TeamGroupAnalysisCtrl', function($stateParams, Role, modelComms, LocalNetworkProfile, model, User) {
    const ctrl = this;
    ctrl.query = {};

    let networkId = LocalNetworkProfile.getNetworkId();

    function updateOtherUsers() {
      ctrl.query = Role.query(function(page) {
        ctrl.roles = page.results;

        ctrl.query = User.query({concise: true, pageSize: LocalNetworkInfo.obtain().getInfo("userCount")}, function(page) {
          ctrl.allUsers = page.results;
          _.forEach(ctrl.allUsers, function(item) {
            item.obfuscated = item.username.indexOf("@") < 0 && item.username.indexOf("*") > 0;
          });
          let selection = function(u) {
            let rolesByNetwork = _.find(u.userRoles.rolesByNetwork, ['network.tagId', networkId]);
            if (!rolesByNetwork) {
              return false;
            }
            let teamGroupRole = _.find(rolesByNetwork.roles, ['teamGroup.id', model.id]);
            if (teamGroupRole) {
              u.teamGroupRole = _.find(ctrl.roles, ['id', teamGroupRole.role.id]);
            }
            return u.teamGroupRole;
          };
          ctrl.users = _.filter(ctrl.allUsers, selection);
          ctrl.otherUsers = _.reject(ctrl.allUsers, selection);
        });
      });
    }

    modelComms.addSubscriber(function(data, type, isNew) {
      if (!type) {
        if (!isNew && !ctrl.otherUsers) {
          updateOtherUsers();
        }
      }
    });

    if (!$stateParams.isNew) {
      updateOtherUsers();
    } else {
      ctrl.query.$resolved = true;
    }

    ctrl.addUser = function() {
      ctrl.hasNewUser = true;
      let defUser = ctrl.otherUsers[0];
      ctrl.users.unshift({id: defUser.id, username: defUser.username, displayName: defUser.displayName, newUser: true, active: true, edited: true, teamGroupRole: ctrl.roles[0]});
    };

    ctrl.newUserChange = function(user) {
      let otherUser = _.find(ctrl.otherUsers, ['id', user.id]);
      user.username = otherUser.username;
      user.displayName = otherUser.displayName;
    };

    ctrl.editUser = function(user) {
      user.edited = true;
      user.active = true;
      user.prevRole = user.teamGroupRole;
    };

    ctrl.cancelUser = function(user, index) {
      user.edited = false;
      user.teamGroupRole = user.prevRole;
      if (user.newUser) {
        _.pullAt(ctrl.users, index);
        ctrl.hasNewUser = false;
      }
    };

    ctrl.saveUser = function(user) {
      User.get({id: user.id}, function(apiUser) {
        let networkRoles = _.find(apiUser.userRoles.rolesByNetwork, ['network.tagId', networkId]);
        if (networkRoles && networkRoles.roles) {
          let teamGroup = _.find(networkRoles.roles, ['teamGroup.id', model.id]);
          if (user.active) {
            let roleUpdate = {id: user.teamGroupRole.id};
            if (teamGroup) {
              teamGroup.role = roleUpdate;
            } else {
              networkRoles.roles.push({teamGroup: {id: model.id}, role: roleUpdate});
            }
          } else {
            let indexEntryRemove = _.findIndex(networkRoles.roles, ['teamGroup.id', model.id]);
            _.pullAt(networkRoles.roles, indexEntryRemove);
          }
        }
        apiUser.savePartial('userRoles').then(function() {
          user.edited = false;
          user.failSave = false;
          if (user.newUser) {
            user.newUser = false;
            _.remove(ctrl.otherUsers, function(u) {
              return u.id === user.id;
            });
          } else if (!user.active) {
            _.remove(ctrl.users, function(u) {
              return u.id === user.id;
            });
            ctrl.otherUsers.push(user);
          }
          ctrl.hasNewUser = false;
        }, function() {
          user.failSave = true;
        });
      }, function() {
        user.failSave = true;
      });
    };
  });

export default MODULE_NAME;