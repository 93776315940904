/**
 * Copyright © 2024 Adnuntius AS.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

import twoPanelTemplate from '../common/two-panel.html';
import editTemplate from './earnings-account-edit.html';
import analysisTemplate from './earnings-account-analysis.html';
import listTemplate from './earnings-account-list.html';

import base from '../base/base';
import earningsAccountActions from './earnings-account-actions-controller';
import sectionsModule from '../base/sections-module';
import routerHelper from '../common/controller/router-helper';
import resources from '../../components/api/resources/resources';
import earningsAccountController from './earnings-account-controller';
import earningsAccountAnalysis from './earnings-account-analysis';
import copyTemplate from "../common/copy-actions.html";
import {PERMISSIONS} from "../../components/auth/auth-constants";
import {LocalNetworkInfo} from "../../components/session/local-network-info";
import _ from 'lodash';
import {getCountryName} from "../common/directives/country-select/countries";

const MODULE_NAME = 'earnings-account-module';

angular.module(MODULE_NAME, [uiRouter, resources, base, sectionsModule, earningsAccountActions, routerHelper, earningsAccountAnalysis, earningsAccountController])

  .config(function($stateProvider, adnRouterHelperProvider) {
    let rh = adnRouterHelperProvider.$get().createRouterAndChildHelper('earnings-accounts.earnings-account', 'EarningsAccount', 'Site', 'app.inv', 'app', listTemplate);
    $stateProvider.state(rh.listPath(), rh.list({
      resolve: {
        extension: /*@ngInject*/ function(LocalNetworkProfile, localUserProfile) {
          return {
            func: function(ctrl, afterFill) {
              if (afterFill) {
                _.forEach(ctrl.items, function(item) {
                  item.countriesExpanded = _.map(item.countries || [], function(country) {
                    return getCountryName(country);
                  }).join(", ");
                });
                return;
              }
              ctrl.eaFields = ['name'];
              if (LocalNetworkInfo.obtain().getInfo("siteCountries").length > 1) {
                ctrl.eaFields.push({label: "countries", param: ''});
              }
              ctrl.eaFields.push("externalReference");
              ctrl.eaFields.push("revenueShare");

              const isSpecialUser = localUserProfile.get("username") === "adnuntius@adnuntius.com" || localUserProfile.get("username") === "broker1@adnuntius.com";
              if (LocalNetworkProfile.isPureMarketplacePlatform() && isSpecialUser) {
                ctrl.eaFields.push({'label': 'fullPage', param: ''});
              }
            }
          };
        },
        readOnly: function(LocalUserPermissions) {
          return !LocalUserPermissions.hasAny(PERMISSIONS.managePublishing.apiKey) && !LocalUserPermissions.hasAny(PERMISSIONS.axPublisherAdmin.apiKey);
        }
      }
    }))
      .state(rh.newItemPath(), rh.newItem())
      .state(rh.itemPath(), rh.modelCommsItem({
        views: {
          'content@app': {
            controller: 'EarningsAccountCtrl',
            controllerAs: 'resource',
            template: twoPanelTemplate
          },
          'context@app': {
            controller: 'EarningsAccountActionsCtrl',
            controllerAs: 'ctrl',
            template: copyTemplate
          },
          'leftPanel@app.earnings-accounts.earnings-account': {
            template: editTemplate,
            controller: 'EarningsAccountCtrl',
            controllerAs: 'resource'
          },
          'rightPanel@app.earnings-accounts.earnings-account': {
            template: analysisTemplate,
            controller: 'EarningsAccountAnalysisCtrl',
            controllerAs: 'resource'
          }
        }
      }, 'EarningsAccount'));
  });

export default MODULE_NAME;